import ko from "knockout";
import Rails from "@rails/ujs";

export default class EmployeeForm {
  constructor() {
    $('[data-controller="employee"]').on('click', '[data-toggle="tab"]', function(e) {
      $(this).closest('.nav').find('[data-toggle="tab"]').not($(this)).removeClass('active');
      var newDropdownTitle = $(this).data('title');
      if (!newDropdownTitle) {
        newDropdownTitle = $(this).text();
      }
      $(this).closest('.nav').find('.dropdown-toggle').html(newDropdownTitle);
    });

    this.loadCurrentTab();
  }

  submitForm(data, event) {
    this.saveCurrentTab();
    let form = event.target.form;
    if (form.getAttribute('data-remote')) {
      Rails.fire(form, 'submit');
    } else {
      form.submit();
    }
  }

  loadCurrentTab() {
    if (!$('.noty_layout').length) { // Only load saved tab when updating
      return;
    }

    var currentTabHref = window.localStorage.getItem(location.pathname+'#currentTab');
    if (currentTabHref === '') {
      return;
    }
    $('[data-controller="employee"] [data-toggle="tab"][href="' + currentTabHref + '"]').click();
  }

  saveCurrentTab() {
    var currentTabHref = $('[data-controller="employee"] [data-toggle="tab"].active').attr('href');
    window.localStorage.setItem(location.pathname+'#currentTab', currentTabHref);
  }
}
