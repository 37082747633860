import { Controller } from "stimulus";
import Noty from "noty";


export default class extends Controller {
  alertElements() {
    return this.element.querySelectorAll(".alert");
  }

  buildNotyAlert(element) {
    const options = this.defaultOptions();

    const text = element.innerHTML;
    if (text) {
      options.text = text;

      const type = element.dataset.level;
      options.type = type;

      new Noty(options).show();
    }
  }

  connect() {
    this.alertElements().forEach((element) => {
      this.buildNotyAlert(element);
    });
  }

  defaultOptions() {
    return {
      layout: "topCenter",
      progressBar: false,
      theme: "bootstrap-v4",
      timeout: 5000,
      visibilityControl: true
    };
  }
}
