import { Controller } from "stimulus";

const Uppy = require("@uppy/core");
const Dashboard = require("@uppy/dashboard");
const XHRUpload = require("@uppy/xhr-upload");

export default class extends Controller {
  connect() {
    this.uppy = Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: 50E6,
        maxNumberOfFiles: 50
      },
    }).
      use(Dashboard, {
        hideProgressAfterFinish: true,
        inline: true,
        locale: {
          strings: this.localeStrings()
        },
        proudlyDisplayPoweredByUppy: false,
        replaceTargetContent: true,
        target: this.element,
      }).
      use(XHRUpload, {
        endpoint: this.element.action,
        fieldName: "document[file]",
        headers: {
          "X-CSRF-Token": this.csrfToken()
        }
      });
  }

  csrfToken() {
    return document.
      querySelector("meta[name=csrf-token]").
      getAttribute("content");
  }

  localeStrings() {
    const string = this.data.get("i18n");
    return JSON.parse(string);
  }
}
