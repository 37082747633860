import ko from "knockout";

export default class DocumentForm {
  constructor() {
    this.collectionIds = ko.observableArray();
  }

  submitForm(data, event) {
    event.target.form.submit();
  }
}
