import { Controller } from "stimulus";
import ko from "knockout";
import DocumentForm from "../models/document_form";

export default class extends Controller {
  connect() {
    const documentForm = new DocumentForm();
    ko.applyBindings(documentForm, this.element);
  }
}
// .click is used to update checkboxes state instead of .checked due to data-binding
$(document).ready(function () {
  $("#select-all-docs").change(function () {
    var isChecked = this.checked;
    $(".selectable-checkbox").each(function () {
      if (isChecked) {
        if (!this.checked) {
          this.click();
        }
      } else {
        if (this.checked) {
          this.click();
        }
      }
    });
  });
});

// toggle documents area collabsing
$(document).ready(function () {
  const DIV_CARD = "div.card";
  $("[data-toggle='card-collapse']").on("click", function (e) {
    let $card = $(this).closest(DIV_CARD);
    $card.toggleClass("card-collapsed");
    e.preventDefault();
    return false;
  });
});

$(document).ready(function () {
  $("[data-toggle='tooltip']").tooltip();
  $("[data-toggle='tooltip']").on("click", function (e) {
    e.preventDefault();
    return false;
  });
});
