import { Controller } from "stimulus";
import ko from "knockout";
import EmployeeForm from "../models/employee_form";

export default class extends Controller {
  connect() {
    const employeeForm = new EmployeeForm();
    ko.applyBindings(employeeForm, this.element);
  }
}
